<template>
  <div class="warp">
    <div class="header">
      <van-nav-bar
          title="答题报告"
          left-arrow fixed
          @click-left="goBack"
      />
    </div>

    <div class="bodyWarp">
      <div class="title">
        <div class="contentLeft">
          <p class="p1">{{ data.subjectFirstTypeName }}</p>
          <p class="p2">{{ data.subjectSecondTypeName }}</p>
          <p class="p2">答题数量：{{ data.completeNumber }}/{{ data.countNumber }}</p>
          <p class="p2">用时：{{ useTime }}</p>
        </div>
        <div class="contentRight">
          <div ref="chart1" class="chart"></div>
          <span class="ti">正确率</span>
        </div>
      </div>

      <div class="data">
        <van-row>
          <van-col span="8">
            <div class="num num1">{{ data.totalMaxAccuracy }}%</div>
            <p>全站正确率最高</p>
          </van-col>
          <van-col span="8">
            <div class="num num2">{{ data.totalAveAccuracy }}%</div>
            <p>全站平均正确率</p>
          </van-col>
          <van-col span="8">
            <div class="num num3">{{ data.defeatAccuracy }}%</div>
            <p>已击败考生</p>
          </van-col>
        </van-row>
      </div>

      <div class="question">
        <div class="t">客观答题情况</div>
        <p>
          <span>答对题数</span>
          <span class="right1">{{ data.rightNumber }}</span>
        </p>
        <p>
          <span>答错题数</span>
          <span class="right">{{ data.errorNumber }}</span>
        </p>

        <div class="btn" @click="angin()">再次挑战</div>
      </div>

      <div class="charts">
        <div class="chartT">
          <span class="desc">成绩曲线</span>
          <span class="descN">(正确率%)</span>
          <span class="lately">最近{{ scoreList.length }}次</span>
        </div>

        <div class="chartss"></div>
      </div>

      <div class="btns">
        <div class="error" @click="errorPager">错题解析</div>
        <div class="allError" @click="allPager">全部解析</div>
      </div>
    </div>

  </div>
</template>

<script>
import {getEexerciseAssignment_paper, getPaperAssignment_paper} from "@/api/questionBank.js";
import { getHMS } from '@/utils/utils.js'

export default {
  data() {
    return {
      data: {},

      scoreList: []
    }
  },
  name: 'answerReport',
  computed:{
        // 用时
        useTime(){
            return getHMS(this.data.sumDuration)
        }
  },
  methods: {
    goBack() {
      if(this.$route.query.source === 'list') {
        this.$router.go(-1);
      }else {
        this.$router.go(-2);
      }
    },
    getCharts() {
      this.$nextTick(() => {
        let chart = this.$echarts.init(document.querySelectorAll('.chart')[0]);
        let chart1 = this.$echarts.init(document.querySelectorAll('.chartss')[0]);
        let option = {
          title: {
            text: Number(this.data.accuracy).toFixed(0) + '%',  //图形标题，配置在中间对应效果图的80%
            left: "center",
            top: "35%",
            textStyle: {
              color: "#6483FF",
              fontSize: 14,
              align: "center"
            }
          },
          series: [
            {
              type: 'pie',
              radius: ['80%', '90%'],  //设置内外环半径,两者差值越大，环越粗
              color: ['#6483FF', '#F2F5FF'],
              label: {
                show: false,
                position: 'center'
              },
              data: [{value: this.data.rightNumber}, {value: this.data.countNumber - this.data.rightNumber}]
            }
          ]
        };
        let option1 = {
          title: {
            show: false
          },
          xAxis: {
            data: []
          },
          yAxis: {
            min: 0,
            max: 100,
            axisLine: {
              show: true
            }
          },
          series: [{
            type: 'line',
            symbol: "circle",
            symbolSize: 8,
            smooth: true,
            data: this.scoreList,
            itemStyle: {//拐点的样式
              color: "rgba(244,253,255,1)",
              borderWidth: 1,
              borderColor: "rgba(157, 185, 250, 1)"
            },
            lineStyle: {//线条的样式
              width: 2,
              color: "rgba(157, 185, 250, 1)"
            },
            areaStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [{
                  offset: 0, color: 'rgba(221, 232, 255, 1)' // 0% 处的颜色
                }, {
                  offset: 1, color: 'rgba(196,211,255,0)' // 100% 处的颜色
                }],
                global: false // 缺省为 false
              }
            }
          }]
        };
        chart.setOption(option);
        chart1.setOption(option1);
      })
    },
    init() {
      let type = this.$route.query.type;
      let id = this.$route.query.id;
      switch (type) {
        case 'chapterExercises':
          this.getEexerciseAssignment_paper({nodeType: 1, nodeId: id});
          break;
        case 'testPaper':
          this.getPaperAssignment_paper(id);
          break;
      }
    },
    getAssignment_paper(testPaperId) {
      getPaperAssignment_paper({testPaperId: testPaperId}).then((res) => {
        console.log('答题报告--------',res)
        if (res.data.code === 0) {
          this.data = res.data.data;
          this.scoreList = res.data.data.scoreList.map(v => v.accuracy);
          this.getCharts();
        }
      })
    },
    getEexerciseAssignment_paper(param) {
      getEexerciseAssignment_paper(param).then((res) => {
        console.log('答题报告22222222--------',res)
        if (res.data.code === 0) {
          this.data = res.data.data;
          this.scoreList = res.data.data.scoreList.map(v => v.accuracy);
          this.getCharts();
        }
      })
    },
    getPaperAssignment_paper(testPaperId) {
      getPaperAssignment_paper({testPaperId: testPaperId}).then((res) => {
        if (res.data.code === 0) {
          this.data = res.data.data;
          this.scoreList = res.data.data.scoreList.map(v => v.accuracy);
          this.getCharts();
        }
      })
    },
    angin() {
      //再次挑战
      // 重新答题
      this.$router.push({
        path: this.$route.query.path,
        query:{
          id: this.$route.query.id,
          tab: this.$route.query.tab
        }
      })
    },
    errorPager() {
      //错误解析
      this.$store.commit('setResolution_method',{key:'answer_res'});
      this.$router.push({
        path: '/questionParsing',
        query:{
          id: this.$route.query.id,
          question: this.$route.query.type,
          type: 'error'
        }
      })
    },
    allPager() {
      //全部解析
      this.$store.commit('setResolution_method',{key:'answer_res'});
      this.$router.push({
        path: '/questionParsing',
        query:{
          id: this.$route.query.id,
          type: 'all',
          question: this.$route.query.type
        }
      })
    }
  },
  created() {
    this.init();
  }
}
</script>

<style lang="less" scoped>
.header {
  font-size: 17px;
  font-weight: 500;
  color: #141414;
  line-height: 24px;
}

/deep/ .van-nav-bar__title {
  font-size: 17px;
  font-weight: 500;
  color: #141414;
}

/deep/ .header .van-icon-arrow-left {
  color: #000;
}

.bodyWarp {
  margin-top: 42px;
  height: calc(100vh - 46px);
  background: #F8F8F8;
  padding: 14px 14px 190px;

  .title {
    height: 120px;
    background: #FFFFFF;
    border-radius: 5px;
    padding: 19px 17px;
    position: relative;

    .contentLeft {
      display: inline-block;

      .p1 {
        height: 22px;
        font-size: 15px;
        font-weight: 500;
        color: #141414;
        line-height: 22px;
      }

      .p2 {
        height: 19px;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        line-height: 19px;
        margin-top: 14px;
      }
    }

    .contentRight {
      position: absolute;
      right: 33px;
      top: 29px;
      display: inline-block;

      .chart {
        width: 58px;
        height: 58px;
        margin: 0 auto;
      }

      .ti {
        margin-top: 5px;
        width: 58px;
        display: inline-block;
        text-align: center;
        height: 18px;
        font-size: 13px;
        font-weight: 400;
        color: #666666;
        line-height: 18px;
      }
    }
  }

  .data {
    height: 82px;
    background: #FFFFFF;
    border-radius: 5px;
    text-align: center;
    margin-top: 9px;

    .num {
      height: 25px;
      font-size: 18px;
      font-weight: 500;
      line-height: 25px;
      margin-top: 19px;
    }

    .num1 {
      color: #5AA153;
    }

    .num2 {
      color: #E36463;
    }

    .num3 {
      color: #EF8A39;
    }

    p {
      height: 17px;
      font-size: 12px;
      font-weight: 400;
      color: #666666;
      line-height: 17px;
    }
  }

  .question {
    margin-top: 9px;
    height: 200px;
    background: #FFFFFF;
    border-radius: 5px;
    padding: 19px 19px 0;

    .t {
      height: 22px;
      font-size: 15px;
      font-weight: 600;
      color: #141414;
      line-height: 22px;
    }

    p {
      border-bottom: 1px solid #DDDDDD;
      padding: 14px 0;

      span {
        height: 19px;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        line-height: 19px;
      }

      .right1 {
        color: #6483FF;
        float: right;
      }

      .right {
        color: #E36463;
        float: right;
      }
    }

    .btn {
      width: 155px;
      margin: 22px auto;
      height: 40px;
      line-height: 40px;
      text-align: center;
      background: #5F7DFF;
      border-radius: 5px;
      color: #fff;
    }
  }

  .charts {
    margin-top: 9px;
    background: #FFFFFF;
    border-radius: 5px;
    padding: 0 18px;

    .chartT {
      padding: 20px 0 0;

      .desc {
        height: 22px;
        font-size: 15px;
        font-weight: 600;
        color: #141414;
        line-height: 22px;
      }

      .descN {
        height: 22px;
        font-size: 14px;
        font-weight: 400;
        color: #666666;
        line-height: 22px;
      }

      .lately {
        float: right;
        width: 64px;
        height: 24px;
        background: #F4F4F4;
        border-radius: 3px;
        color: #666666;
        text-align: center;
        line-height: 24px;
      }
    }

    .chartss {
      margin-top: -30px;
      height: 300px;
    }
  }

  .btns {
    width: 100%;
    height: 76px;
    line-height: 76px;
    background: #FFFFFF;
    position: fixed;
    bottom: 0;
    left: 0;

    .error {
      display: inline-block;
      width: 168px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      background: #EA6A76;
      border-radius: 5px;
      font-size: 14px;
      font-weight: 500;
      color: #FFFFFF;
      margin: 0 15px;
    }

    .allError {
      display: inline-block;
      width: 168px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      background: #5F7DFF;
      border-radius: 5px;
      font-size: 14px;
      font-weight: 500;
      color: #FFFFFF;
    }
  }
}

</style>
